<template>
    <div>
    <NuxtPage></NuxtPage>
    </div>
</template>

<style scoped>
body {

    background-color:black !important;
}
</style>
